.actionButtons > a:first-of-type{
    margin-right: auto;
    margin-left: 12px;
}

.actionButtons > button:first-of-type{
    margin: 0 auto;
}

.actionButtons > button:nth-child(2){
    color: var(--white);
}