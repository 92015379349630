.content{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.content > svg{
    margin: 0;
    width: 100%;
}

.content > img{
    margin: 0;
    width: 100%;
}

.subtitle{
    font-size: 18px;
}