.header{
    padding: 22px 0;
    background-color: var(--green);
    color: var(--white);
    width: 100%;
}

.koordinatorHeader{
    /*background-color: magenta;*/
    padding: 22px 0;
    color: var(--white);
    width: 100%;
    background-color: var(--blue);

}

.headingContent{
    /*background-color: blue;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 57px;
    max-width: var(--maxPageWidth);
    margin: 0 auto;
}

.headingContent > button{
    margin-left: auto;
}

.heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 18px;
}


.h1{
    /*background-color: #8f0071;*/
    align-items: center;
}

.link{
    text-decoration: none;
    color: var(--white);
}

.fnfLogo{
    /*background-color: #ff9900;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: 8px;
    color: var(--white);
    text-decoration: none;
}

.subtitle{
    /*background-color: #b13e43;*/
    text-transform: uppercase;
    margin: auto 18px auto 8px;
}

/*-------------------------------- mobil -----------------------------------------------------------------------------*/

@media screen and (max-width: 599px){
    /* 599 for å stemme med xsDown */

    .header{
        padding: 18px 16px;
        width: unset;
    }

    .koordinatorHeader{
        /*background-color: yellow;*/
        padding: 18px 16px;
        width: unset;
    }

    .koordinatorHeader > .headingContent{
        /*background-color: green;*/
        display: flex;
        flex-direction: row;
    }

    .headingContent{
        justify-content: center;
    }

    .fnfLogo{
        display: none;
    }
}