.chipsRoot{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.chipsRoot > .filterChip{
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px;
}

.chipsRoot > .filterChipError{
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px;
    color: var(--error-dark);
    /*background-color: var(--error-dark);*/
}

.errorMsg {
    color: var(--error-dark);
    font-size: 14px;
}
