@import url('https://fonts.googleapis.com/css?family=Poppins');
:root{
  /* Colors */
--blue: #265B6F;
--light-blue: #58A49B;
--green: #199581;
--light-green: #8BCAB3;
--orange: #FF6C44;
--light-orange: #F2A581;
--purple: #8F0071;
--light-purple: #FFE5FF;
  --error: #E76E6E;
  --error-dark: #B13E43;

--white: #F5F5F5;
--black: #141414;

--maxPageWidth: 1700px; /* duplicated in App.css (see comment) */


}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Abadi MT Pro", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




