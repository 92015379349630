.menu {
    /*background-color: darkblue;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: 24px;
}

.menu > button {
    /*margin-left: 6px;*/
    color: var(--white);
}

.menuText {
    cursor: pointer;

}

.progress {
    /*color: yellow;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: 8px;
}