.App {
    display: grid;
    min-height: 100vh;
    /* header - main - footer */
    grid-template-rows: auto 1fr auto;
}

.clickable-link {
    cursor: pointer;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.App_MainContent {
    padding-bottom: 40px;
}

.main{
    /*background-color: yellow;*/
    max-width: var(--maxPageWidth);
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: 1700px){ /* var(--maxPageWidth) not allowed in media query! */
        /*background-color: red;*/
        margin: 0 auto 0 0;
    }
}

.main > div{
    /*background-color: lightgreen;*/
    /*margin: 0 8px;*/
}

.main-heading{
    border-left: 6px solid var(--light-orange);
    padding-left: 12px;
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 24px;
    overflow-wrap: anywhere;
}

.sub-heading{
    font-size: 23px;
    font-weight: 800;
    margin-bottom: 0;

}

.border-sub-heading{
    border-bottom: 5px solid var(--light-orange);
    width: 36px;
}

.publicMain {
    /*background-color: lightgreen;*/
    margin: 0 18px 0 18px;

    @media screen and (max-width: 599px){
        /* 599 for å stemme med xsDown */

        /*background-color: blue;*/
        margin: 0 8px 0 8px;
    }
}



