.container {
    font-size: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fffb9e;
    margin-top: 5px;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    border: 1px solid #dee45c;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
}

.container span {
    padding-right: 10px;
}

.closeAlert::after {
    text-decoration: underline;
    cursor: pointer;
    content: "Skjul meldingen X";
}

.popLink {
    text-decoration: underline;
    cursor: pointer;
}

.popupBox {
    padding: 12px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    font-size: 16px;
}

.popupBox > a {
    color: #1976d2;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
}

.popupBox > a > svg {
    margin-left: 4px;
    font-size: 16px;
}


@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .closeAlert::after {
        content: "X";
    }
}
