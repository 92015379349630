.table{
    /*background-color: lightgoldenrodyellow;*/
}

.table th{
    color: var(--blue);
}

.table th span{
    color: var(--blue);
}

.table th span:active{
    color: var(--blue);
}

.table tbody tr{
    cursor: pointer;
}

.table tbody tr:hover{
    background-color: #58A49B50;
}

.table tbody tr:nth-of-type(odd):hover{
    background-color: #58A49B50;}

.table tbody tr:nth-of-type(odd){
    background-color: var(--white);
}

/*
   ===============  Disse benyttes bare utover max breakpoint, så lenge alle breakpoints er stylet lengre ned.
*/
.titleRow{
    /*background-color: lightgray;*/
    width: 29%;
    word-break: break-word;
}

.omradeRow{
    width: 14%;
    word-break: break-word;
}

.deadlineRow{
    /*background-color: lightgray;*/
    width: 8%;
}

.statusRow{
    width: 10%;
}

.areaCell{
    /*background-color: lightgray;*/
    width: 8%;
}

.sakstypeCell {
    width: 12%;
}

.opprettetCell {
    /*background-color: lightgray;*/
    width: 8%;
}

.oppdatertCell {
    width: 11%;
}

.deadlineWithStatusIcon {
    display: flex;
    justify-content: right;
}

.cellValueNumeric{
    text-align: right;
}

.cellValueNumericStrikeThrough{
    text-align: right;
    text-decoration: line-through;
}

.statusWithStatusIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.statusWithStatusIcon > div {
    margin-left: auto;
}

.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

.container > div{
    margin-left: auto;
}

.topContainer {
    /*background-color: orange;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row;

    @media screen and (max-width: 959px) {
        /* 959 for å stemme med smDown */
        flex-flow: column;
    }
}

@media screen and (max-width: 1279px) {
    /* 1279 for å stemme med mdDown */
    .titleRow{
        /*background-color: red;*/
        width: 42%;
    }
    .omradeRow{
        width: 20%;
    }
    .deadlineRow{
        width: 12%;
    }
    .statusRow{
        width: 14%;
    }
    .areaCell{
        width: 12%;
    }
}

@media screen and (max-width: 959px) {
    /* 959 for å stemme med smDown */

    .titleRow{
        /*background-color: blue;*/
        width: 56%;
    }
    .omradeRow{
        width: 28%;
    }
    .deadlineRow{
        width: 16%;
    }
}

@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .titleRow{
        /*background-color: lightgreen;*/
        width: 58%;
    }
    .omradeRow{
        width: 26%;
    }
    .deadlineRow{
        width: 16%;
    }

    .container > div{
        margin-left: auto;
    }

    .table tbody tr td{
        padding: 16px 4px;
        /*overflow-wrap: anywhere;*/
        word-break: break-word;
    }

    .table thead tr th{
        padding: 16px 12px;
    }
}
