.alert{

}

.actionButtons > button:first-of-type{
    margin-right: auto;
    margin-left: 12px;
}

.link{
    color: var(--black);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.link > svg{
    margin-left: 4px;
    font-size: 14px;
}
