.container{
    /*background-color: blueviolet;*/
    align-items: center;
    max-width: 678px;
    width: 100%;
    margin: 0 auto 24px;
}


.deadline{
    display: flex;
    flex-direction: row;
    background-color: var(--light-green);
    padding: 8px 32px;
    margin: 0;
    width: 180px;
    border-radius:  6px 6px 0 0 ;
}

.date{
    margin: 0 0 0 48px;
}

.metaInformation{
    border: 3px solid var(--light-green);
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
    padding: 12px 24px;
    border-radius:  0 6px 6px 6px;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading{
    font-weight: 800;
    margin: 0;
    text-align: center;
}

.subtitleStatus{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subtitleStatus > div{
    margin-left: 8px;
}

.subtitle{
    margin: 0;
    text-align: center;
}

@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .subtitleStatus > div{
        margin-left: 4px;
    }
}

