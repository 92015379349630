.contentContainer{
    /*background-color: yellow;*/
    margin-left: 24px;
}

.AdminForm_Form {
    align-items: baseline;
}

.AdminForm_Autocomplete {
    display: contents;
}

.helperText{
    background-color: var(--white);
    padding: 16px 22px 12px 22px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    border-radius: 12px;
    justify-content: space-around;
    color: var(--black);
}

.helperText > svg{
    font-size: 32px;
    margin: 0 8px 0 0 ;

    align-self: center;

}

.helperText > p{
    margin:0;
}

.summary{
    /*background-color: #0066cc;*/
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    flex-direction: column;
    min-height: 382px;
    justify-content: space-between;
}

.summary > .quill > .ql-containter > .ql-editor{
    padding: 12px 4px 0;
    overflow-y:  scroll;
    height: 318px !important;
}

.summaryLabelError{
    font-size: 14px;
    margin-bottom: 4px;
    height: 20px;
    color: #f44336;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.summaryLabelError > svg{
    margin-left: 4px;
    font-size: 20px;
}

.summaryError{
    border: solid 1px #f44336;
    border-radius: 6px;
}

.errorMessage{
    color: #f44336;
    margin: 3px 0 0 14px;
    font-size: 12px;
}

.label{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 4px;
    height: 20px;
}

.label > svg{
    margin-left: 4px;
    font-size: 20px;
}
@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .contentContainer{
        /*background-color: yellow;*/
        margin-left: 0;
    }
}