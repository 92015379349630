.chipsRoot{
    /*background-color: lightgreen;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.chipsRoot > .filterChip{
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px;
}

.chipsRootMobile{
    /*background-color: yellow;*/

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.chipsRootMobile > .chipsRootMobileNoFilter {
    /*background-color: lightgray;*/

    padding-top: 8px;
    padding-left: 16px;
}

.chipsRootMobileNoFilter > span {
    /*background-color: #b13e43;*/

    font-size: 14px;
}

.chipsRootMobile > .filterChip{
    /*background-color: red;*/
    display: flex;
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 10px;
    margin-bottom: 0;
}

.chipsRootMobile > .chipsRootMobileSpacer {
    /* Trix: spacer to overlap Filter button...
       See buttonAbsoultePositionerMobile in sakerOverview.module.css */

    /*background-color: yellowgreen;*/

    width: 127px;
    height: 40px;
}
