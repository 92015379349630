.quill{
    display: flex;
    flex-direction: column;
}



.ql-toolbar{
    border-radius: 4px 4px 0 0 ;
}

.ql-container{
    box-sizing: unset;
    font-family: unset;
    font-size: unset;
    margin: unset;
    position: unset;
    border-radius:  0 0 4px 4px;
}

.ql-editor{
    padding: 12px 4px 0;
    overflow-y:  scroll;
    height: 330px;
}

.ql-tooltip{
    top: 500px!important;
}

@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .ql-tooltip{
        top: 1200px!important;
    }
}

