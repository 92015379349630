.sakMain {
    /*background-color: lightgray;*/

    display: flex;
    flex-direction: column;
    height: 100%;

    margin: 0 18px 0 18px;

    @media screen and (max-width: 599px){
        /* 599 for å stemme med xsDown */

        /*background-color: blue;*/
        margin: 0 8px 0 8px;
    }
}

.content{
    /*background-color: lightgreen;*/

    display: flex;
    flex-direction: column;

    max-width: 600px;
    position: relative;
    height: 100%;
}

.actionContainer{
    /*background-color: #0066cc;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 24px 0;
}

.lesHeleSakenContainer{
    /*background-color: yellow;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /*margin-bottom: -16px;*/
}

.externalLinkContainer{
    /*background-color: lightpink;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 12px;
    margin-bottom: 12px;
}

.externalLinkContainer > a > button {
    /* Adjust App theme to style centered (but keep overall margin size) */
    margin-left: 24px;
    margin-bottom: 12px;
    color: var(--white);
}

.externalLinkContainer > a:nth-last-child(1) > button{
    /*color: var(--white);*/
}

.externalLinkContainer > a:nth-last-child(2) > button{
    /*color: var(--white);*/
}

/*
    NB! Style Quill editor's class when viewing Quill-generated (custom!) markup.
        Ref. https://github.com/slab/quill/issues/3193#issuecomment-1954938850
*/
.sakMain > .content > [class="ql-editor"] {
    overflow-y: auto;
    height: fit-content;
    padding-bottom: 17px;
}

.updateInfo{
    /*background-color: lightpink;*/
    margin-top: 40px;
}

.updateInfo > p {
    position: absolute;
    bottom: 0;
    font-style: italic;
    padding: 0 0 4px 0;
}

.inlineInformation{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.inlineInformation > label{
    padding-right: 8px;
    font-weight: 800;
}

.inlineInformation > p{
    margin: 4px 0;
}

@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .externalLinkContainer{
        flex-direction: column;
    }

    .externalLinkContainer a{
        margin-left: auto;
    }

    .externalLinkContainer > a:nth-last-child(1) > button{
        /*margin-bottom: 12px;*/
    }

    .externalLinkContainer > a:nth-last-child(2) > button{
        /*margin-bottom: 12px;*/
    }

    .actionContainer > button {
        margin-left: 6px;
    }

    .lesHeleSakenContainer{
        /*background-color: yellow;*/
        margin-bottom: 0;
    }


}