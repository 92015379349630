.footer {
    background-color: var(--green);
    color: var(--white);

}

.footerKoordinator {
    background-color: var(--blue);
    color: var(--white);

}

.footerContent {
    max-width: var(--maxPageWidth);
    margin: 0 auto;
    padding: 24px 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.heading {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}
.subtitle {
    margin: 0;
}

.fnf {
    margin: auto 18px;
}

.fnfLogo {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-column-gap: 12px;
    margin-top: 12px;
    text-transform: uppercase;
    color: var(--white);
    text-decoration: none;
}
.supportedBy {
    max-width: 400px;
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;
    color: var(--white);
    margin: 0 18px 0 18px;

}

.kantega {
    color: var(--white);
    text-decoration: none;
    margin: auto 18px;
}

/*-------------------------------- mobil -----------------------------------------------------------------------------*/

@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    .footerContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: unset;
    }

    .footer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: unset;
    }

    .footerKoordinator {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: unset;
    }

    .footer div{
        padding-bottom: 42px;
    }

    .footerKoordinator div{
        padding-bottom: 42px;
    }

    .supportedBy{
        padding: 0;
    }
}