.topGrid {
}

.filterGrid {
}

.mainGrid {
}

.headingContainer{
    display: flex;
    flex-direction:row ;
    align-items: center;
}

.headingContainer > button{
    margin-left: auto;
}

.chipsContainer{
    /*background-color: yellow;*/
    display: flex;
    flex-direction:row ;
    align-items: center;
    margin: 24px 0;
}

.chipsContainer > button{
    margin: 0;
}

.heading{
    color: var(--blue);
    font-weight: 800;
}

.subtitle{
    font-size: 14px;
    margin-bottom: 4px;
}

.subHeading{
    color: var(--blue);
    margin-top: 28px;
    margin-bottom: 0;
}

ul{
    margin: 0;
}

.link{
    color: var(--black);
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.link > svg{
    margin-left: 4px;
    font-size: 14px;
}


/*Styling filter for mobile*/

.buttonContainerMobile{
    /*background-color: #9933ff;*/

    margin: 12px 4px 20px;
    display: flex;
    flex-direction: column;
    min-height: 48px;

    position: relative;
}

.buttonContainerMobile > p{
    font-size: 14px;
    font-style: italic;
}

.buttonContainerMobile > .filterButtonAbsoultePositionerMobile {
    /* Trix: spacer to overlap Filter button...
       See chipsRootMobileSpacer in searchChips.module.css */

    /*background-color: lightblue;*/
    /*margin: 4px;*/

    position: absolute;
    top: 0;
    left: 0;
}

.buttonContainerMobile > .filterButtonFilterEmptyMobile {
    /*background-color: deeppink;*/
    display: flex;
    justify-content: center;
}

.filterHeaderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-left: 6px;
    margin-right: 6px;
}

.filterHeaderContainer > button {
    /*color: var(--white);*/
    /*margin-right: auto;*/
    margin-right: 6px;
    /*margin-left: auto;*/
    margin-left: 6px;
}

.filterHeaderContainer > button:last-child{
    /*color: var(--white);*/
}

.filterHeaderContainer > button > span {
    padding: 6px;
}

.filterHeaderContainer > button > span > span{
    margin: 0;
}

.bottomNav > button{
    margin-left: 24px;
    margin-right: 24px;
    width: 100%;
}

.bottomNav > button:nth-child(1){
    /*color: var(--white);*/
}

.isLoadingIndicatorMobile {
    /* NB! SakerOverview.addScrollListenerForSpinner */
    /*background-color: red;*/
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}
