.alert{

}

.actionButtons > button:first-of-type{
    margin-right: auto;
    margin-left: 12px;
}

@media screen and (max-width: 599px) {
    /* 599 for å stemme med xsDown */

    button:first-of-type {
        margin-left: 6px;
    }
}