.createSakMain {
    /*background-color: seagreen;*/

    display: flex;
    flex-direction: column;
    height: 100%;

    margin: 0 18px 0 18px;

    @media screen and (max-width: 599px){
        /* 599 for å stemme med xsDown */

        /*background-color: blue;*/
        margin: 0 8px 0 8px;
    }
}
