.subHeading{
    color: var(--blue);
}

.list{
    list-style: none;
    padding: 0;
}

.listSecond{
    list-style: none;
    padding-left: 16px;
}

.listItem{
    padding-top: 4px;
}

.listItem > input {

}

.listItem > label {
    cursor: pointer;
}

input{
    accent-color: var(--blue);
}

.toggle{
    margin-top: 6px;
}